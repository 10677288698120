<template lang="pug">
div.play_project
  SegmentedProgress(
    :numSegments="numSegments"
    :progress="progress"
    :currentSegment="currentSegment"
    v-on:tapped="handleProgressSegmentTapped"
    v-show="showProgress"
  )
  div.play_button(v-show="showPlayButton" v-on:click="togglePlayPause")
    img(:src="playIconSrc")
  div.bottom_controls
    img#sound_control.clickable(v-show="showOwnerControls" v-on:click="toggleAskTitle"  style="touch-action: manipulation" :src="titleButtonSrc")
    img#sound_control.clickable(v-show="showOwnerControls && !showMultiSelect" v-on:click="showMultiSelect=true"  style="touch-action: manipulation" :src="musicButtonSrc")
    div.multiselect_dark(v-show="showMultiSelect")
      Multiselect(
        v-model="selectedSoundTrackLabel",
        v-bind:options="soundTrackLabels",
        :searchable="true",
        :internal-search="false",
        @search-change="insertCustomLink",
        :hide-selected="true",
        :close-on-select="true",
        :show-labels="false",
        :allow-empty="false",
        placeholder="Select music or type in url to music"
      )
    div#title(v-if="showAsk || showAskFromProject" v-bind:class="{title_smaller: isSmallScreen}") {{ askTitle }}

  div.embed-container(v-on:click="togglePlayPause")
    video#play1(playsinline v-bind:class="{blur_video: showPlayButton}")
    video#play2(playsinline v-bind:class="{blur_video: showPlayButton}")
    video#video_cover_blur(playsinline muted)
    audio#soundtrack(autoplay loop crossOrigin="anonymous")
    div.black_background
</template>

<script>
/* global gtag */

import { PLAY_PROJECT_QUERY } from '../graphql/queries'
import { TRANSCODE_VIDEO_MUTATION, UPDATE_PROJECT_MUTATION, UPDATE_PROJECT_SOUNDTRACK_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
import Multiselect from 'vue-multiselect'
import SegmentedProgress from '../components/SegmentedProgress'
// import { defaultSoundTrackVolume, setupAudioContext, setVolume, adjustVolume } from '../lib/audio'
// import config from '../appConfig'

export default {
  name: 'PlayProject',
  props: {
    slug: String,
    muted: {
      type: Boolean,
      default: false
    },
    showAsk: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    showProgress: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Header,
    SegmentedProgress,
    Multiselect,
  },
  watch: {
    muted () {
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")

      audio.muted = this.muted
      video1.muted = this.muted
      video2.muted = this.muted
    },
    playProject () {
      this.$emit('project_updated', this.playProject)
    },
    videoUrls () {
      if (this.videoUrls.length <= 0) return
      this.setupPlayback()
      this.setSelectedSoundTrack(this.playProject.audioUrl)
    },
    selectedSoundTrackLabel: function() {
      this.selectedSoundTrack = this.soundTrackOptions.find(option => option.label == this.selectedSoundTrackLabel)
      if (this.selectedSoundTrack.link != this.playProject.audioUrl) {
        this.resetPlayback()
        if (this.isOwner) {
          this.updateProject()
        } else if (this.$route.query.token) {
          this.updateProjectSoundTrack()
        }
      }
    },
  },
  computed: {
    showAskFromProject () {
      return this.playProject?.showAsk
    },
    showOwnerControls () {
      return this.isOwner || this.$route.query.token
    },
    isOwner () {
      return this.playProject && this.playProject.isOwner
    },
    musicButtonSrc () {
      return process.env.BASE_URL + "music_button.svg"
    },
    titleButtonSrc () {
      return process.env.BASE_URL + "title_button.svg"
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    recipientName () {
      return this.playProject?.recipientName ? this.playProject?.recipientName : ""
    },
    videoUrls () {
      if (!this.playProject) return []
      return this.playProject?.videoUrls
    },
    audioUrl () {
      if (!this.playProject) return ""
      return this.playProject?.audioUrl
    },
    askTitle () {
      return this.playProject?.videos[this.currentPlaybackIndex].ask.text
    },
    numSegments () {
      if (!this.playProject) return 0
      return this.playProject?.videoUrls.length
    },
    currentSegment () {
      return this.currentPlaybackIndex + 1
    },
    soundTrackLabels () {
      return this.soundTrackOptions.map(option => option.label)
    },
  },
  data () {
    return {
      currentPlaybackIndex: 0,
      playProject: null,
      isSmallScreen: false,
      showPlayButton: false,
      stopPlayback: false,
      timer: null,
      progress: 0,
      selectedSoundTrack: null,
      selectedSoundTrackLabel:null,
      showMultiSelect: false,
      displayAskTitle: false,
      soundTrackOptions: [
        {label:"no music", link:null},
        {label:"warm guitar", link:"https://wishwell-media.s3.amazonaws.com/warm_guitar_soft.mp3"},
        {label:"upbeat guitar", link:"https://wishwell-site-content.s3.amazonaws.com/music/upbeat_guitar_soft.mp3"},
        {label:"upbeat ukele", link:"https://wishwell-site-content.s3.amazonaws.com/music/upbeat_indie_soft.mp3"},
        {label:"whistling indie", link:"https://wishwell-site-content.s3.amazonaws.com/music/upbeat_indie_whistle_soft.mp3"},
        {label:"upbeat indie", link:"https://wishwell-site-content.s3.amazonaws.com/music/upbeat_indie2_soft.mp3"},
      ],
    }
  },
  apollo: {
    playProject () {
      return {
        // gql query
        query: PLAY_PROJECT_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug
          }
        },
        skip () {
          return !this.slug
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    toggleAskTitle: function() {
      console.log(this.playProject.showAsk)
      if (this.isOwner) {
        this.updateShowAsk(!this.playProject.showAsk)
      }
    },
    insertCustomLink: function(text) {
      if (text == "" || !text) return
      this.soundTrackOptions = this.soundTrackOptions.filter(option => !option.custom)
      this.soundTrackOptions.push(
        {
          label: "custom",
          link: text,
          custom: true
        }
      )
    },
    setSelectedSoundTrack: function(soundtrack_url) {
      const selected = this.soundTrackOptions.find(option => option.link == soundtrack_url)
      if (selected) {
        this.selectedSoundTrackLabel = selected.label
      } else if (soundtrack_url && soundtrack_url != "") {
        this.insertCustomLink(soundtrack_url)
        this.setSelectedSoundTrack(soundtrack_url)
      }
    },
    updateShowAsk: function(showAsk) {
      if (!this.playProject) return

      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            id: this.playProject.projectId,
            showAsk: showAsk
          }
        }
      }).then((data) => {
        // console.log(data)
        this.playProject.showAsk = data.data.updateProject.showAsk
        console.log(data.data)
      }).catch((error) => {
        console.error(error)
      })
    },
    updateProject: function() {
      if (!this.playProject) return

      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            id: this.playProject.projectId,
            soundtrackUrl: this.selectedSoundTrack.link
          }
        }
      }).then((data) => {
        // console.log(data)
        this.playProject.audioUrl = data.data.updateProject.soundtrackUrl
        this.setupPlayback()
      }).catch((error) => {
        console.error(error)
      })
    },
    updateProjectSoundTrack: function() {
      if (!this.playProject) return

      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_SOUNDTRACK_MUTATION,
        variables: {
          soundtrackUrl: this.selectedSoundTrack.link,
          slug: this.slug,
          token: this.$route.query.token,
        }
      }).then((data) => {
        // console.log(data)
        this.playProject.audioUrl = data.data.updateSoundTrack.soundtrackUrl
        this.setupPlayback()
      }).catch((error) => {
        console.error(error)
      })
    },
    handleProgressSegmentTapped: function(segment) {
      document.getElementById("play1").pause()
      document.getElementById("play2").pause()

      this.currentPlaybackIndex = segment - 1
      this.showPlayButton = false
      this.playNextVideo()
    },
    nextIndex: function(index) {
      return index < (this.videoUrls.length -1) ? index+1 : 0
    },
    nextVideo: function() {
      this.currentPlaybackIndex = this.nextIndex(this.currentPlaybackIndex)
        this.playNextVideo()
    },
    resetPlayback: function () {
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")
      video1.pause()
      video2.pause()
      audio.pause()
      bg_video_cover_blur.pause()
      this.stopProgressUpdate()
      this.showPlayButton = true

      video1.removeEventListener('ended',this.nextVideo)
      video2.removeEventListener('ended',this.nextVideo)
      this.currentPlaybackIndex = 0
    },
    setupPlayback: function() {
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")
      video1.addEventListener('ended',this.nextVideo)
      video2.addEventListener('ended', this.nextVideo)

      // once we have the video metadata, cover video depending on aspect ratio
      video1.onloadeddata = () => {
        // video1.style.objectFit = ((video1.videoWidth > video1.videoHeight) && (window.innerWidth > window.innerHeight)) || ((video1.videoWidth < video1.videoHeight) && (window.innerWidth < window.innerHeight)) || (video1.videoHeight == video1.videoWidth)  ? "cover" : "contain"
        video1.style.objectFit = ((video1.videoWidth < video1.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }
      // once we have the video metadata, cover video depending on aspect ratio
      video2.onloadeddata = () => {
        // video2.style.objectFit = ((video2.videoWidth > video2.videoHeight) && (window.innerWidth > window.innerHeight)) || ((video2.videoWidth < video2.videoHeight) && (window.innerWidth < window.innerHeight)) || (video2.videoHeight == video2.videoWidth)  ? "cover" : "contain"
        video2.style.objectFit = ((video2.videoWidth < video2.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }

      audio.src = this.audioUrl
      if (this.muted) audio.muted = true
      // audio.load()
      video1.volume = 1
      video2.volume = 1
      audio.volume = 1 // 0.07  // volume does not work in  ios safari so it is now controlled in producing the audio files

      // setupAudioContext(audio)
      // setVolume(defaultSoundTrackVolume)
      // if (this.audioUrl) audio.play()

      // console.log(config)
      this.playNextVideo()
      this.startProgressUpdate()

    },
    togglePlayPause: function() {
      // this will hide the playbutton if it exists.
      this.showPlayButton = false

      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")
      const audio = document.getElementById("soundtrack")
      var front = video1
      if (this.currentPlaybackIndex%2 == 1) {
        front = video2
      }
      if (front.paused) {
        front.play()
        // setVolume(defaultSoundTrackVolume)
        bg_video_cover_blur.play()
        if (!this.muted) {
          if (this.audioUrl) audio.play()
        } else {
          front.muted = true
        }
        this.startProgressUpdate()
        this.showPlayButton = false
        if (this.nextIndex(this.currentPlaybackIndex) == 0) this.stopPlayback = true
      } else {
        front.pause()
        audio.pause()
        bg_video_cover_blur.pause()
        this.stopProgressUpdate()
        this.showPlayButton = true
      }
    },
    playNextVideo: async function() {
      this.showPlayButton = false
      const nextIndex = this.nextIndex(this.currentPlaybackIndex)
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")

      var front = video1
      var back = video2
      if (this.currentPlaybackIndex%2 == 1) {
        front = video2
        back = video1
      }

      if (front.src != this.videoUrls[this.currentPlaybackIndex]) {
        front.src = this.videoUrls[this.currentPlaybackIndex]
        front.load()
      }
      if (back.src != this.videoUrls[nextIndex]) {
        back.src = this.videoUrls[nextIndex]
        back.load()
      }

      bg_video_cover_blur.src = front.src
      bg_video_cover_blur.load()

      front.style.zIndex = "10"
      back.style.zIndex = "1"

      // cover video if its in landscape
      // front.style.objectFit = ((front.videoWidth > front.videoHeight) && (window.innerWidth > window.innerHeight)) || ((front.videoWidth < front.videoHeight) && (window.innerWidth < window.innerHeight)) || (front.videoHeight == front.videoWidth)  ? "cover" : "contain"
      front.style.objectFit =  ((front.videoWidth < front.videoHeight) && (window.innerWidth > window.innerHeight))   ? "containt" : "cover"

      if (this.stopPlayback) {
        // we have reached the end of the videos so stop everything
        this.stopPlayback = false
        this.stopProgressUpdate()
        // adjustVolume(0, 1700).then(() => {
        //   audio.pause()
        //   audio.currentTime = 0
        // })
        audio.pause()
        this.showPlayButton = true
        this.$emit('ended')
        return
      } else if (nextIndex == 0 && !this.loop) {
        this.stopPlayback = true
      }

       // play video
       // !IMPORTANT: this assumes ios safari is going to fail, causing playbutton to appear and then allowing audiosetup to occur in a user click event handler
      try {
        await front.play()
        bg_video_cover_blur.play()

        if (!this.muted) {
          if (this.audioUrl) audio.play()
        } else {
          front.muted = true
        }
      } catch(err) {
        audio.pause()
        this.showPlayButton = true
        if (/NotSupportedError/i.test(err)) {
          this.transcodeCurrentVideo()
        }
      }
    },
    transcodeCurrentVideo: function () {
      this.$toast.error("Video format is not compatible with this browser. Wait a few minutes while we update the video file. Or try another browser.", {timeout: 8000})
      this.$apollo.mutate({
        mutation: TRANSCODE_VIDEO_MUTATION,
        variables: {
          videoId: this.playProject.videos[this.currentPlaybackIndex].id
        },
      }).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
    },
    openFullscreen: function(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    stopProgressUpdate: function () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    startProgressUpdate: function() {
      this.stopProgressUpdate()
      this.timer = setInterval(() => {
        const video1 = document.getElementById("play1")
        const video2 = document.getElementById("play2")

        const playing = (this.currentPlaybackIndex%2 == 1) ? video2 : video1
        const progress = Math.round(playing.currentTime/playing.duration*100)
        // const timeLeft = playing.duration - playing.currentTime
        this.progress = isNaN(progress) ? 0 : progress
      }, 10)
    },
  },
  beforeUnmount: function () {
    this.stopProgressUpdate()
  },
  beforeRouteUpdate (to, from, next) {
    this.stopProgressUpdate()
    // react to route changes...
    // don't forget to call next()
    next()
  },
  mounted: function() {
    // window.vue = this
    this.displayAskTitle = this.showAsk
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/play_project'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.play_project {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: relative;
  /* padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index:100;
  top: 0px;
  left: 0px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.embed-container video#video_cover_blur {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
  z-index: 7;
}

.blur_video {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
}

.video_cover {
  object-fit: cover;
}

.black_background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 5;
}
#title {
  color: #ffffff;
  font-size: 30px;
  font-weight: normal;
  width: 96%;
  padding: 20px 2%;
}

.title_smaller{
  font-size: 24px;
}

.play_button {
  position: absolute;
  z-index: 2000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: rgb(0,0,0,0.55);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play_button:hover {
  background-color: rgb(0,0,0,0.35);
}
.play_button img {
  margin-left:8px;
  width: 28px;
  height: 32px;
}

.bottom_controls {
  position: absolute;
  z-index: 800;
  bottom: 20px;
  left: 2%;
  width: 96%;
}

.multiselect_dark {
  max-width: 300px;
  position: relative;
  border: none;
  text-align: left;
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0,0,0,0.50);
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}

#sound_control {
  max-height: 50px;
  /* width: 80px; */
}

</style>
